#logo {
  padding: 8px 0 4px 16px;
  height: 60px;
}
@media (max-width: 480px) {
  #logo {
    height: 45px;
    padding: 4px 0 2px 8px;
  }
}

#header-container {
  margin-top: 0;
  display: flex;
  box-shadow: 0 0 8px 0 #69E9F5;
  width: 100%;
  flex-direction: row;
}

#location-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 4px #69E9F5;
  border-radius: 16px;
}
@media screen and (max-width: 480px) {
  #location-container {
    margin: 12px 0 0;
    width: 400px;
  }
}
@media screen and (min-width: 481px) {
  #location-container {
    margin: 16px 0 0;
    width: 600px;
  }
}

#filtered-container {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 480px) {
  #filtered-container {
    margin: 12px 0 0;
    gap: 4px;
  }
}
@media screen and (min-width: 481px) {
  #filtered-container {
    margin: 16px 0 0;
    gap: 8px;
  }
}

#location-selector {
  display: flex;
  flex-direction: row;
  text-align: center;
  border-radius: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 4px;
  font-family: Verdana;
  color: #645FCD;
}
@media screen and (max-width: 480px) {
  #location-selector {
    width: 400px;
    font-size: 12px;
  }
}
@media screen and (min-width: 481px) {
  #location-selector {
    font-size: 14px;
    width: 600px;
  }
}

#state-selector {
  display: flex;
  flex-direction: row;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #645FCD;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 4px;
  font-family: Verdana;
  color: #645FCD;
  justify-content: center;
  box-shadow: 0 2px 4px #69E9F5;
}
@media screen and (max-width: 480px) {
  #state-selector {
    width: 100px;
    font-size: 12px;
  }
}
@media screen and (min-width: 481px) {
  #state-selector {
    width: 125px;
    font-size: 14px;
  }
}

#filtered-location-selector {
  display: flex;
  flex-direction: row;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #645FCD;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 4px;
  font-family: Verdana;
  color: #645FCD;
  justify-content: center;
  box-shadow: 0 2px 4px #69E9F5;
}
@media screen and (max-width: 480px) {
  #filtered-location-selector {
    width: 275px;
    font-size: 12px;
  }
}
@media screen and (min-width: 481px) {
  #filtered-location-selector {
    width: 460px;
  }
}

#app-body {
  font-family: Verdana;
  color: #645FCD;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 480px) {
  #app-body {
    font-size: 12px;
  }
}
@media screen and (min-width: 481px) {
  #app-body {
    font-size: 14px;
  }
}

body {
  margin: unset;
  padding: unset;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-family: Verdana;
  border: 1px #645FCD solid;
  border-radius: 16px;
  box-shadow: 0 2px 4px #69E9F5;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .card-container {
    margin: 12px 0 0;
    padding: 6px;
    width: 400px;
  }
}
@media screen and (min-width: 481px) {
  .card-container {
    margin: 16px 0 0;
    padding: 12px;
    width: 600px;
  }
}

p {
  margin: unset !important;
}

#date-selectors-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 480px) {
  #date-selectors-container {
    margin: 8px 0 0;
    width: 400px;
  }
}
@media screen and (min-width: 481px) {
  #date-selectors-container {
    margin: 16px 0 0;
    width: 600px;
  }
}

@media screen and (max-width: 480px) {
  #date-selectors-container p {
    margin: 0 0 8px !important;
  }
}
@media screen and (min-width: 481px) {
  #date-selectors-container p {
    margin: 0 0 16px !important;
  }
}

#date-selectors {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  #date-selectors {
    column-gap: 8px;
    width: 400px;
    flex-direction: column;
    gap: 8px;
  }
}
@media screen and (min-width: 481px) {
  #date-selectors {
    column-gap: 16px;
    width: 600px;
    flex-direction: row;
  }
}

@media screen and (max-width: 480px) {
  #date-search-button {
    margin: 4px 0 0;
  }
}

#date-selectors input {
  text-align: center;
  color: #645FCD;
  border-radius: 16px;
  border: 1px solid #645FCD;
  box-shadow: 0 2px 4px #69E9F5;
  font-family: Verdana;
}
@media screen and (max-width: 480px) {
  #date-selectors input {
    width: 250px;
    padding: 2px 2px;
  }
}
@media screen and (min-width: 481px) {
  #date-selectors input {
    width: 150px;
    padding: 4px 4px;
  }
}

label {
  margin: 0 4px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#state-filter-checkbox {
  margin: 1px 0 0 4px;
}

#state-filter-switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  #state-filter-switch {
    margin: 8px 0;
  }
}
@media screen and (min-width: 481px) {
  #state-filter-switch {
    margin: 16px 0;
  }
}

#state-filter-switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}
@media screen and (max-width: 480px) {
  #state-filter-switch-container {
    margin: 8px 0 0;
  }
}
@media screen and (min-width: 481px) {
  #state-filter-switch-container {
    margin: 16px 0 0;
  }
}

#date-selectors label {
  display: flex;
  justify-content: space-around;
  flex: 1;
}

.search-button {
  border-radius: 16px;
  border: 1px solid #645FCD;
  background-color: #645FCD;
  color: #ffffff;
  font-family: Verdana;
  box-shadow: 0 2px 4px #69E9F5;
}

.search-button:hover {
  cursor: pointer;
}

.appointment-list-item {
  color: #645FCD;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px #645FCD solid;
  border-radius: 16px;
  padding: 8px;
  box-shadow: 0 2px 4px #69E9F5;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .appointment-list-item {
    margin: 4px;
  }
}
@media screen and (min-width: 481px) {
  .appointment-list-item {
    margin: 8px;
  }
}

.modal-title {
  color: #645FCD !important;
}

#page-number-holder {
  display: flex;
  flex-direction: row;
}

.page-number {
  display: flex;
}

#scan-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

#scan-modal-body div {
  color: #645FCD !important;
}

#next-available-container {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  #next-available-container {
    width: 400px;
    gap: 2px;
    height: 130px;
  }
}
@media screen and (min-width: 481px) {
  #next-available-container {
    width: 600px;
    gap: 4px;
    height: 150px;
  }
}

#search-results-container {
  display: flex;
  justify-content: center;
  height: 100px;
}
@media screen and (max-width: 480px) {
  #search-results-container {
    width: 400px;
    gap: 2px;
  }
}
@media screen and (min-width: 481px) {
  #search-results-container {
    width: 600px;
    gap: 8px;
  }
}

#view-all-button, #refresh-button {
  margin: 4px 0 0;
}

#notification-disclaimer-body {
  color: #645FCD;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 480px) {
  #notification-disclaimer-body {
    gap: 4px;
  }
}
@media screen and (min-width: 481px) {
  #notification-disclaimer-body {
    gap: 8px;
  }
}

.modal-text {
  color: #645FCD;
}

@media screen and (max-width: 480px) {
  #book-button {
    margin: 0 0 0 4px;
  }
}
@media screen and (min-width: 481px) {
  #book-button {
    margin: 0 0 0 8px;
  }
}

#page-number-holder {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  #page-number-holder {
    margin: 8px 0 0;
    gap: 4px;
  }
}
@media screen and (min-width: 481px) {
  #page-number-holder {
    margin: 16px 0 0;
    gap: 8px;
  }
}

.greyed-out-button {
  border-radius: 16px;
  border: 1px solid grey;
  background-color: grey;
  color: #ffffff;
  font-family: Verdana;
}

#scan-message-container {
  display: flex;
  justify-content: center;
  margin: 4px 0 0;
}

.error-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #645FCD;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 2px 4px #69E9F5;
}
@media screen and (max-width: 480px) {
  .error-container {
    margin: 9px 0 0;
    gap: 4px;
    width: 400px;
    padding: 8px 12px;
  }
}
@media screen and (min-width: 481px) {
  .error-container {
    margin: 18px 0 0;
    gap: 8px;
    width: 600px;
    padding: 4px 8px;
  }
}

#search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px 0 #69E9F5;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;
}
@media screen and (max-width: 480px) {
  #footer {
    height: 45px;
    font-size: 12px;
  }
}
@media screen and (min-width: 481px) {
  #footer {
    height: 60px;
    font-size: 14px;
  }
}

#location-card {
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appointment-modal {
  width: 400px !important;
}

