#logo {
  @media (max-width: 480px) {
    height: 45px;
    padding: 4px 0 2px 8px;
  }

  padding: 8px 0 4px 16px;
  height: 60px;
}

#header-container {
  margin-top: 0;
  display: flex;
  box-shadow: 0 0 8px 0 #69E9F5;
  width: 100%;
  flex-direction: row;
}

#location-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 4px #69E9F5;
  border-radius: 16px;

  @media screen and (max-width: 480px) {
    margin: 12px 0 0;
    width: 400px;
  }

  @media screen and (min-width: 481px) {
    margin: 16px 0 0;
    width: 600px;
  }
}

#filtered-container {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 480px) {
    margin: 12px 0 0;
    gap: 4px;
  }

  @media screen and (min-width: 481px) {
    margin: 16px 0 0;
    gap: 8px;
  }
}

#location-selector {
  display: flex;
  flex-direction: row;
  text-align: center;
  border-radius: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 4px;
  font-family: Verdana;
  color: #645FCD;

  @media screen and (max-width: 480px) {
    width: 400px;
    font-size: 12px;
  }

  @media screen and (min-width: 481px) {
    font-size: 14px;
    width: 600px;
  }
}

#state-selector {
  @media screen and (max-width: 480px) {
    width: 100px;
    font-size: 12px;
  }

  @media screen and (min-width: 481px) {
    width: 125px;
    font-size: 14px;
  }

  display: flex;
  flex-direction: row;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #645FCD;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 4px;
  font-family: Verdana;
  color: #645FCD;
  justify-content: center;
  box-shadow: 0 2px 4px #69E9F5;
}

#filtered-location-selector {
  @media screen and (max-width: 480px) {
    width: 275px;
    font-size: 12px;
  }

  @media screen and (min-width: 481px) {
    width: 460px;
  }
  
  display: flex;
  flex-direction: row;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #645FCD;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 4px;
  font-family: Verdana;
  color: #645FCD;
  justify-content: center;
  box-shadow: 0 2px 4px #69E9F5;
}

#app-body {
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  @media screen and (min-width: 481px) {
    font-size: 14px;
  }

  font-family: Verdana;
  color: #645FCD;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  margin: unset;
  padding: unset;
}

.card-container {
  @media screen and (max-width: 480px) {
    margin: 12px 0 0;
    padding: 6px;
    width: 400px;
  }

  @media screen and (min-width: 481px) {
    margin: 16px 0 0;  
    padding: 12px;
    width: 600px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-family: Verdana;
  border: 1px #645FCD solid;
  border-radius: 16px;
  box-shadow: 0 2px 4px #69E9F5;
  text-align: center;
}

p {
  margin: unset !important;
}

#date-selectors-container {
  @media screen and (max-width: 480px) {
    margin: 8px 0 0;
    width: 400px;
  }

  @media screen and (min-width: 481px) {
    margin: 16px 0 0;
    width: 600px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}

#date-selectors-container p {
  @media screen and (max-width: 480px) {
    margin: 0 0 8px !important;
  }

  @media screen and (min-width: 481px) {
    margin: 0 0 16px !important;
  }
}

#date-selectors {
  @media screen and (max-width: 480px) {
    column-gap: 8px;
    width: 400px;
    flex-direction: column;
    gap: 8px;
  }

  @media screen and (min-width: 481px) {
    column-gap: 16px;
    width: 600px;  
    flex-direction: row;
  }

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

#date-search-button {
  @media screen and (max-width: 480px) {
    margin: 4px 0 0;
  }
}

#date-selectors input {
  @media screen and (max-width: 480px) {
    width: 250px;
    padding: 2px 2px;
  }

  @media screen and (min-width: 481px) {
    width: 150px;
    padding: 4px 4px;
  }

  text-align: center;
  color: #645FCD;
  border-radius: 16px;
  border: 1px solid #645FCD;
  box-shadow: 0 2px 4px #69E9F5;
  font-family: Verdana;
}

label {
  margin: 0 4px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#state-filter-checkbox {
  margin: 1px 0 0 4px;
}

#state-filter-switch {
  @media screen and (max-width: 480px) {
    margin: 8px 0;
  }

  @media screen and (min-width: 481px) {
    margin: 16px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
}

#state-filter-switch-container {
  @media screen and (max-width: 480px) {
    margin: 8px 0 0;
  }

  @media screen and (min-width: 481px) {
    margin: 16px 0 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

#date-selectors label {
  display: flex;
  justify-content: space-around;
  flex: 1;
}

.search-button {
  border-radius: 16px;
  border: 1px solid #645FCD;
  background-color: #645FCD;
  color: #ffffff;
  font-family: Verdana;
  box-shadow: 0 2px 4px #69E9F5;
}

.search-button:hover {
  cursor: pointer;
}

.appointment-list-item {
  @media screen and (max-width: 480px) {
    margin: 4px;
  }

  @media screen and (min-width: 481px) {
    margin: 8px;
  }

  color: #645FCD;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px #645FCD solid;
  border-radius: 16px;
  padding: 8px;
  box-shadow: 0 2px 4px #69E9F5;
  text-align: center;
}

.modal-title {
  color: #645FCD !important;
}

#page-number-holder {
  display: flex;
  flex-direction: row;
}

.page-number {
  display: flex;
}

#scan-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

#scan-modal-body div {
  color: #645FCD !important;
}

#next-available-container {
  @media screen and (max-width: 480px) {
    width: 400px;
    gap: 2px;
    height: 130px;
  }

  @media screen and (min-width: 481px) {
    width: 600px;
    gap: 4px;
    height: 150px;
  }

  display: flex;
  justify-content: center;
}

#search-results-container {
  @media screen and (max-width: 480px) {
    width: 400px;
    gap: 2px;
  }

  @media screen and (min-width: 481px) {
    width: 600px;
    gap: 8px;
  }

  display: flex;
  justify-content: center;
  height: 100px;
}

#view-all-button, #refresh-button {
  margin: 4px 0 0;
}

#notification-disclaimer-body {
  @media screen and (max-width: 480px) {
    gap: 4px;
  }

  @media screen and (min-width: 481px) {
    gap: 8px;
  }

  color:#645FCD;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.modal-text {
  color:#645FCD;
}

#book-button {
  @media screen and (max-width: 480px) {
    margin: 0 0 0 4px;
  }

  @media screen and (min-width: 481px) {
    margin: 0 0 0 8px;
  }
}

#page-number-holder {
  @media screen and (max-width: 480px) {
    margin: 8px 0 0;
    gap: 4px;
  }

  @media screen and (min-width: 481px) {
    margin: 16px 0 0;
    gap: 8px;
  }

  display: flex;
  justify-content: center;
}

.greyed-out-button {
  border-radius: 16px;
  border: 1px solid grey;
  background-color: grey;
  color: #ffffff;
  font-family: Verdana;
}

#scan-message-container {
  display: flex;
  justify-content: center;
  margin: 4px 0 0;
}

.error-container {
  @media screen and (max-width: 480px) {
    margin: 9px 0 0;
    gap: 4px;
    width: 400px;  
    padding: 8px 12px;
  }

  @media screen and (min-width: 481px) {
    margin: 18px 0 0;  
    gap: 8px;  
    width: 600px;
    padding: 4px 8px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #645FCD;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 2px 4px #69E9F5;
}

#search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px 0 #69E9F5;  
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;

  @media screen and (max-width: 480px) {
    height: 45px;
    font-size: 12px;
  }

  @media screen and (min-width: 481px) {
    height: 60px;
    font-size: 14px;
  }
}

#location-card {
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appointment-modal {
  width: 400px !important;
}